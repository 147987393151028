<template>
	<div class="container vld-parent">
		<loading
			:active.sync="isLoading"
			:is-full-page="true"
			color="#114C8E"
		></loading>
		<b-row>
			<h1 class="text-primary">Edit Tender</h1>
		</b-row>
		<b-row>
			<b-card style="width: 100%">
				<b-row>
					<b-col>
						<DeliveryDetails
							Mode="Edit"
							:ValidationModel="$v"
							:key="deliveryDetailKey"
						/>
						<!-- <b-dropdown-divider class="divider-margin"/> -->
						<ProductDetails />
						<!-- <b-dropdown-divider class="divider-margin"/> -->
						<SpecialInstructions />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-right">
						<b-button
							id="btnExitWithoutSaving"
							variant="outline-danger"
							@click="exitWithoutSaving()"
							class="mr-3"
							>Exit Without Saving</b-button
						>

						<b-button variant="primary" @click="showOrderReviewModal()"
							>Review Order Tender</b-button
						>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-right pd-10" v-if="$v.$anyError">
						<span class="text-danger"
							>Tender has errors! Please fix them and try again.
						</span>
					</b-col>
				</b-row>
			</b-card>
		</b-row>
		<!-- Order Confirmation Modal -->
		<b-modal
			v-model="showOrderConfirmationModal"
			@hide="modalClosed()"
			:title="orderConfirmationModalTitle"
			:no-close-on-esc="true"
			:no-close-on-backdrop="true"
			size="lg"
		>
			<b-row v-if="editSuccessful && !orderEditFailed">
				<b-col>
					<b-alert variant="success" class="text-center" show
						>Order Tender Updated Successfully</b-alert
					>
				</b-col>
			</b-row>
			<b-row v-if="orderEditFailed && spgUpdateResponse !== null">
				<b-col>
					<b-alert variant="danger" class="text-center" show
						>Order Tender Update Failed, Please Try Again</b-alert
					>
				</b-col>
			</b-row>
			<b-row v-if="editSuccessful && !orderEditFailed && this.division == 'SPG' && spgUpdateResponse !== null">
				<b-col>
					<b-alert variant="warning" class="text-center" show
						>Old order number: {{$route.params.id}}</b-alert>
				</b-col>
				<b-col>
					<b-alert variant="success" class="text-center" show
						>New order number: {{spgUpdateResponse.kagOrderNumber}}</b-alert
					>
				</b-col>
			</b-row>
			<b-row v-if="tenderId !== ''">
				<b-col>
					Tender Id: &nbsp;
					<label class="text-primary">{{ tenderId }}</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					Delivery Date: &nbsp;
					<label class="text-primary">{{ formattedDateTime }}</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					PO/Sales #: &nbsp;
					<label class="text-primary">{{
						poNumber !== "" ? poNumber : "N/A"
					}}</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					3PO #: &nbsp;
					<label class="text-primary">{{
						thirdPartyPO !== "" ? thirdPartyPO : "N/A"
					}}</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					Notes: &nbsp;
					<label class="text-primary">{{ notes !== "" ? notes : "N/A" }}</label>
				</b-col>
			</b-row>
			<b-row class="text-primary mt-3">
				<b-col>
					<h4>Products</h4>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-table
						ref="productsTable"
						:items.sync="selectedProducts"
						:responsive="true"
							:fields="this.division!=='SPG' ? table.fields : spgTable.fields"
					>
						<template v-slot:cell(deliverySite)="row">
							<div class="text-wrap text-break" style="max-width:125px;">
								{{ getDeliveryAddress(row.value, "N/A") }}
							</div>
						</template>
						<template v-slot:cell(shipper)="row">{{
							row.value.name ? row.value.name : "N/A"
						}}</template>
						<template v-slot:cell(supplier)="row" :visible="this.division!=='SPG'">{{
							row.value.name ? row.value.name : "N/A"
						}}</template>
						<template v-slot:cell(accountOf)="row" :visible="this.division!=='SPG'">{{
							row.value.name ? row.value.name : "N/A"
						}}</template>
						<template v-slot:cell(product)="row">{{
							row.value.name ? row.value.name : "N/A"
						}}</template>
						<template v-slot:cell(amount)="row">{{ buildQuantityString(row) }}</template>

						<!-- <template v-slot:cell(amountUnitOfMeasure)="row">{{
							row.value}} + ' gggggGGGGG ' +{{this.amountUnitOfMeasure}}
						</template> -->
					</b-table>
				</b-col>
			</b-row>
			<div slot="modal-footer" class="w-100" v-if="!editSuccessful">
				<b-button
					class="float-right ml-2 mr-2"
					variant="primary"
					@click="updateOrderTender()"
					>Submit Updated Tender</b-button
				>
				<b-button
					class="float-right"
					variant="default"
					@click="showOrderConfirmationModal = false"
					>Cancel</b-button
				>
			</div>
			<div slot="modal-footer" class="w-100" v-if="editSuccessful">
				<b-button
					class="float-right"
					variant="primary"
					@click="showOrderConfirmationModal = false"
					>Close</b-button
				>
			</div>
		</b-modal>
		<slideout-panel></slideout-panel>
	</div>
</template>

<script>
/* eslint-disable */
//#region
import { mapState } from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DeliveryDetails from "@/components/orders/DeliveryDetails";
import ProductDetails from "@/components/orders/ProductDetails";
import SpecialInstructions from "@/components/orders/SpecialInstructions";
import { formatShipmentStartAndEndDateTime, convertTime12to24 } from "@/shared/utils/date-formatter";
import { formatDisplayDate } from "@/shared/utils/date-formatter";
import mixin from "@/shared/mixin";
import {
	deliveryWindows,
	deliveryTimes
} from "@/shared/constants";
//#endregion

	export default {
	components: {
		DeliveryDetails,
		ProductDetails,
		SpecialInstructions,
		Loading
	},
	mixins: [mixin],
	data() {
		return {
			editSuccessful: false,
			showOrderConfirmationModal: false,
			orderConfirmationModalTitle: "Confirm Tender Details",
			isLoading: false,
			deliveryDetailKey: 0,
			tenderId: "",
			orderStatus: null,
			spgTable: {
				fields: [
					"deliverySite",
					"shipper",
					"product",
					"amount",
					"actions"
				],
				emptyText: "You have no products.  Please click the 'Add Product' button above to add some."
			},
			table: {
				fields: [
					"deliverySite",
					"shipper",
					"supplier",
					"accountOf",
					"product",
					"amount"
				],
				emptyText: "You have no products.  Please click the 'Add Product' button above to add some."
			},
			spgUpdateResponse: null
		};
	},
	async beforeMount() {
		try {
      const requestData = {
				orderNumber: this.$route.params.id,
				division: this.division,
				preferHeader: "return=freight-based"
			};

      const order = await this.$store.dispatch(
				"order/getOrderDetails",
				requestData
			);

			this.orderStatus = order.status;

			if (order.status !== "AVL" && order.division !== "SPG") {
				this.$router.push({ name: "order-tenders" });
			} else {
				await this.$store.dispatch("newOrderTender/setOrderTenderData", order);
			}
		}catch(error){
      const errorMessage = handleRequestError(error);
			this.$snotify.error(errorMessage, "Error");
		}
		this.deliveryDetailKey += 1; // then load the delivery detail
	},
		async created() {
        this.$store.getters.Data;
		this.init();
		this.isLoading = true;

		this.$root.$on("isEditLoading", val => {
			this.isLoading = val;
		});

		try {
			//Load All BillTos
			await this.$store.dispatch(
				"newOrderTender/getAllBillTos",
				this.$store.state.user.jwtToken
			);

			//Load All Consignees
			if (this.$store.state.newOrderTender.billTos.length > 0) {
				await this.$store.dispatch("newOrderTender/getConsignees", {
					jwtToken: this.$store.state.user.jwtToken,
					billTo: this.$store.state.newOrderTender.billTos[0].id
				});
			}
		} catch (error) {
      const errorMessage = handleRequestError(error);
			this.$snotify.error(errorMessage, "Error");
		}
		this.isLoading = false;
	},
	destroyed() {
		this.$store.dispatch("newOrderTender/clearOrderTenderData");
	},
	methods: {
		init() {
			
		},
		modalClosed() {
			if (this.editSuccessful === true) {
				this.editSuccessful = false;
				this.showOrderConfirmationModal = false;
				this.tenderId = "";
				if(this.spgUpdateResponse !== null) {
					this.filterObject.orderReferenceNumber = `${this.$route.params.id}${this.spgUpdateResponse.kagOrderNumber !== 0 ? 
						", "+this.spgUpdateResponse.kagOrderNumber : "" }`;
				}
				this.$router.push({ name: "order-tenders" });
			}
		},
		exitWithoutSaving() {
			//send back to all orders.
			this.$router.push({ name: "order-tenders" });
		},
		showOrderReviewModal() {
			// check delivery date valiation -- only needed for not customized
			if (this.selectedDeliveryTime !== "custom") {
				// end date is 24 hours away
				let timemode = deliveryTimes[this.selectedDeliveryTime];
				let end = this.getDateFromOutput(
					this.selectedDeliveryDate,
					timemode.end
				);
        const endcutoff = end.diff(moment(), "minutes");
				if (endcutoff < deliveryWindows.endCutoffHours * 60 - 5) {
					// give 5 minutes delay
					this.$snotify.error(
						"Delivery Date/Time not valid; re-select or use Custom!",
						"Error"
					);
					return;
				}
			}

			// this.$refs.productsTable.refresh();
			if (this.selectedProducts.length < 1) {
				this.$snotify.error("You must add at least 1 product.", "Error");
				this.isLoading = false;
				return;
			}



			// if (
			//   !hasTwentyFourHourBufferFromCreatedDate(
			//     this.createdDate,
			//     dates.shipmentStartDateTime
			//   )
			// ) {
			//   var formattedDate = moment(this.createdDate).format(
			//     "MM/DD/YYYY hh:mm a"
			//   );
			//   this.$snotify.error(
			//     `Delivery time must be at least 24 hours from inital order creation date: ${formattedDate}`,
			//     "Error"
			//   );
			//   this.isLoading = false;
			//   return;
			// }

			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.showOrderConfirmationModal = true;
			} else {
				this.$snotify.error("form validation error.", "Error");
			}
		},
		async updateOrderTender() {
			this.showOrderConfirmationModal = false;
			this.isLoading = true;

			try {
				const billTo = this.$store.state.newOrderTender.selectedBillTo.id;

				//no-undef
				const selectedBillTo = _.find(this.billTos, { id: billTo });
				// no-redeclare
				const objBillTo = {
					id: selectedBillTo.id,
					name: selectedBillTo.name
				};

				const dates = formatShipmentStartAndEndDateTime(
					this.selectedDeliveryTime,
					this.selectedDeliveryDate,
					this.selectedFromCustomDate,
					this.selectedFromCustomTime,
					this.selectedToCustomDate,
					this.selectedToCustomTime
				);


        let totalAmount = 0;
        const details = [];
				this.selectedProducts.forEach(stop => {
					totalAmount += parseInt(stop.amount);
					details.push({
						deliverySite: stop.deliverySite,
						shipper: stop.shipper,
						supplier: stop.supplier,
						accountOf: stop.accountOf,
						amount: stop.amount,
						product: stop.product,
						notes: "",
						components: [],
						amountUnitOfMeasure: stop.amountUnitOfMeasure,
						callForQuantity: stop.callForQuantity,
						loadWindow: stop.loadWindow,
            deliveryWindow: stop.deliveryWindow,
					});
				});
				
				let tmpShipmentStartTime = dates.shipmentStartDateTime.slice(11,16)+''+dates.shipmentStartDateTime.slice(19,22).toUpperCase();
				let tmpShipmentStartDateTime = new Date(dates.shipmentStartDateTime.slice(0,10)+' '+convertTime12to24(tmpShipmentStartTime)).toISOString();

				let tmpShipmentEndTime = dates.shipmentEndDateTime.slice(11,16)+''+dates.shipmentEndDateTime.slice(19,22).toUpperCase();
				let tmpShipmentEndDateTime = new Date(dates.shipmentEndDateTime.slice(0,10)+' '+convertTime12to24(tmpShipmentEndTime)).toISOString();

        const objOrderTender = {
					customId: this.shipmentId,
					division: this.division,
					billTo: objBillTo,
					deliveryTimeWindow: this.selectedDeliveryTime,
					shipmentStartDateTime: moment(dates.shipmentStartDateTime).format('YYYY-MM-DDTHH:mm:ss'),
					shipmentEndDateTime: moment(dates.shipmentEndDateTime).format('YYYY-MM-DDTHH:mm:ss'),
					// shipmentStartDateTime: tmpShipmentStartDateTime,
					// shipmentEndDateTime: tmpShipmentEndDateTime,
         	po: this.poNumber,
					notes: this.notes,
					details: details,
					sender: "TRPFCS",
					amountUnitOfMeasure: this.amountUnitOfMeasure !== '' ? this.amountUnitOfMeasure : this.selectedAmountUnitOfMeasure,
          			templateId: this.templateId,
					status: (this.division === "SPG" && this.orderStatus === "PND") ? "Pending" : null,
					thirdPartyPO: this.thirdPartyPO
				};

        const requestData = {
          uId: this.$route.params.id,
          orderTender: objOrderTender,
          jwtToken: this.$store.state.user.jwtToken
        };
				
				var result = null;

				if (objOrderTender.division === "FDG") {
					result = await this.$store.dispatch(
						"newOrderTender/updateOrderTender",
						requestData
				);
				} else if (objOrderTender.division === "SPG") {
					result = await this.$store.dispatch(
						"newOrderTender/updateSPGOrderTender",
						requestData
					);
				}

				if (objOrderTender.division === "SPG"){
					if(result)
						this.spgUpdateResponse = result.data;
				}
				
				this.editSuccessful = true;
				this.showOrderConfirmationModal = true;
			} catch (error) {
        const errorMessage = handleRequestError(error);
				this.$snotify.error(errorMessage, "Error");
			}

			this.isLoading = false;
		},
		async clearNewOrderTenderData() {
			this.$v.$reset();
			this.$store.dispatch("newOrderTender/clearOrderTenderData");
			this.editSuccessful = false;
			this.showOrderConfirmationModal = false;
			this.tenderId = "";
			this.deliveryDetailKey += 1;
		},
		buildQuantityString(row) {
			if (row.item.callForQuantity) {
				return "Call For Quantity";
			}

			return row.value + " " + row.item.amountUnitOfMeasure;
		}
	},
	computed: {
		...mapState("newOrderTender", [
			"accountOfs",
			"billTos",
			"shippers",
			"suppliers",
			"commodities",
			"consignees",
			"selectedDeliveryDate",
			"selectedDeliveryTime",
			"selectedFromCustomDate",
			"selectedFromCustomTime",
			"selectedToCustomDate",
			"selectedToCustomTime",
			"poNumber",
			"notes",
			"selectedProducts",
			"shipmentId",
			"division",
			"createdDate",
      "templateId",
			"thirdPartyPO",
			"selectedBillTo"
		]),
		formattedDateTime() {
      let selectedFromDateTime, selectedToDateTime;

			if (this.selectedDeliveryTime === "custom") {
				selectedFromDateTime = moment(
					`${this.selectedFromCustomDate}T${this.selectedFromCustomTime}`
				);
				selectedToDateTime = moment(
					`${this.selectedToCustomDate}T${this.selectedToCustomTime}`
				);
			}

			return formatDisplayDate(
				this.selectedDeliveryTime,
				this.selectedDeliveryDate,
				selectedFromDateTime,
				selectedToDateTime
			);
		},
		division() {
			return this.$route.params.division ? this.$route.params.division : this.division;
		},

		filterObject: {
      get() {
				return this.$store.state.order.filterObject
      },
      set(value) {
        this.$store.dispatch("order/setFilterObject", value);
      }
    },

		orderEditFailed(){
			return this.spgUpdateResponse === null || this.spgUpdateResponse.kagOrderNumber === 0
		}
	},
	validations() {
		if (this.selectedDeliveryTime !== "custom") {
			if (this.saveTemplate) {
				return {
					selectedDeliveryTime: {
						// required,
						// hasSixHourBuffer
					},
					selectedDeliveryDate: {
						//required
					},
					templateName: {
						required
					}
				};
			} else {
				return {
					selectedDeliveryTime: {
						// required,
						// hasSixHourBuffer
					},
					selectedDeliveryDate: {
						//required
					}
				};
			}
		} else {
			if (this.saveTemplate) {
				return {
					selectedDeliveryTime: {
						// required,
						// hasSixHourBuffer
					},
					selectedFromCustomDate: {
						//required
					},
					selectedFromCustomTime: {
						//required
					},
					selectedToCustomDate: {
						//required
					},
					selectedToCustomTime: {
						// required
					},
					templateName: {
						required
					},
					selectedDeliveryDate: {
						//required
					}
				};
			} else {
				return {
					selectedDeliveryTime: {
						// required,
						// hasSixHourBuffer
					},
					selectedFromCustomDate: {
						//required
					},
					selectedFromCustomTime: {
						//required
					},
					selectedToCustomDate: {
						//required
					},
					selectedToCustomTime: {
						//required
					},
					selectedDeliveryDate: {
						//required
					}
				};
			}
		}
	}
};
</script>


